<template>
  <div>
    <b-row align-v="center">
      <b-col lg="7">
        <div v-html="$t('home.summary-close')"></div>
      </b-col>
      <b-col lg="5">
        <a
          :href="`${$t('baseurl')}/img/opengraph/home.jpg`"
          :title="$t('home.click-to-enlarge')"
        >
          <img
            alt=""
            class="mb-5 w-100"
            :src="`${$t('baseurl')}/img/opengraph/home.jpg`"
          />
        </a>
      </b-col>
    </b-row>

    <h2 v-html="$t('home.title-subscription')"></h2>

    <b-row align-v="center">
      <b-col
        lg="7"
      >
        <div v-html="$t('home.summary-subscription')"></div>
      </b-col>
      <b-col
        lg="5"
      >
        <form
          action="https://listmonk.framasoft.org/subscription/form"
          method="post"
        >
          <label
            for="f-nl-email"
            class="sr-only"
            v-html="$t('home.your-email')"
          ></label>
          <b-form-input
            id="f-nl-email"
            aria-describedby="nlHelp"
            name="email"
            type="email"
            size="40"
            :placeholder="$t('home.your-email')"
            :title="$t('home.type-your-email')"
          />
          <p class="text-center m-0">
            <b-button
              size="lg"
              type="submit"
              variant="primary h-100 d-inline-flex align-items-center my-4"
            >
              <i class="fas fa-bell fc-g2"></i>
              <span v-html="$t('home.subscribe')"></span>
              <span
                class="sr-only"
                v-html="$t('home.to-the-newsletter')"
              ></span>
            </b-button>
          </p>

          <div style="display: none">
            <input
              id="872e937c"
              checked
              name="l"
              type="checkbox"
              value="872e937c-7d84-4123-bb85-9e020c7671c4"
            />
          </div>
        </form>
      </b-col>
    </b-row>

    <p
      class="signature text-right mt-4 mb-0"
      v-html="$t('home.update', {date : $t('meta.date-edit')})"
    ></p>
  </div>
</template>

<script>
export default {
  methods: {
    date(date) {
      return this.$t(new Date(date).toLocaleDateString(this.$t('lang'), {
        year: 'numeric',
        month: 'long',
      }), '-K');
    },
  },
};
</script>

<style lang="scss">
#home {
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid var(--f-g3);
  }

  img {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 500px;
    display: block;
    margin: 0 auto;
  }

  li:target {
    border: 3px solid #edd8fd;
    border-radius: 5px;
    margin: 5px;
  }

  .table {
    td {
      text-align: center;
      vertical-align: middle;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }

    li {
      padding: 1rem;
    }

    @media (min-width: 768px) {
      li {
        min-width: 170px;
      }
    }
  }

  #limit {
    td:nth-child(2) {
      border-top: 4px solid var(--f-o4);
    }
    td:nth-child(3) {
      border-top: 4px solid var(--f-b4);
    }
  }

  #close {
    td:nth-child(2) {
      border-top: 4px solid var(--f-j3);
    }
    td:nth-child(3) {
      border-top: 4px solid var(--f-o4);
    }
    td:nth-child(4) {
      border-top: 4px solid var(--f-b9);
    }
  }
}

.fa-2x::before,
a .fa-2x::before {
  color: var(--f-g6) !important;
}

a:hover, a:focus {
  .fa-2x::before {
    color: var(--f-g7) !important;
  }
}

#list-services {
  a {
    .card {
      color: var(--f-g9);
      border-color:  var(--f-g3);
    }

    &:hover, &:focus {
      text-decoration: none !important;
      .card {
        background: var(--f-g1);
      }
    }
  }
}
</style>
